* {
  margin: 0;
  padding: 0;
}

html,
body {
  background: black;
  overflow: hidden;
}

.webgl {
  position: relative;
  outline: none;
}
/* Footer */
.main-footer {
  margin-top: 0;
  padding: 1.5rem;
  text-align: center;
}
  .main-footer a {
    color: orange;
    font-size: 1.25rem;
    text-decoration: none;
  }
    .main-footer a:hover {
      color: lime;
    }
